import React from 'react';
import ReactDOM from 'react-dom/client';
import './reset.sass';
import './style.sass';
import {createBrowserRouter, RouterProvider} from "react-router-dom";
import UsersStore from "./store/user.store";
import LangsStore from "./store/langs.store";
import StaticStore from "./store/static.store";
import routes from "./routes";
import {SocketProvider} from "./providers/socket.provider";

export const Context = React.createContext(null);

const router = createBrowserRouter(routes, {
    future: {
        v7_normalizeFormMethod: true,
    },
});

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
    <Context.Provider value={
        {
            user_store: new UsersStore(),
            lang_store: new LangsStore(),
            static_store: new StaticStore(),
        }
    }>
        <SocketProvider>
            <RouterProvider router={router} />
        </SocketProvider>

    </Context.Provider>
);