import axios from "axios";

const $host = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL,
    // withCredentials: true,
    headers: {
        'Content-Security-Policy': 'http://localhost:3000',
        'Content-Type': 'multipart/form-data urf8',
        "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS"
    }
});

const authInterceptor = config => {
    config.headers['Authorization'] = `Bearer ${localStorage.getItem('token')}`
    return config
};

$host.interceptors.request.use(authInterceptor)

export {
    $host
}