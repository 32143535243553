import {$host} from "../http/axios";

export default class MessageMethods {
    static addMessage = async (formData) => {
        const {data} = await $host.post(`/message/add`, formData);
        return data;
    }

    static getMessage = async (id) => {
        const {data} = await $host.post(`/message/get/${id}`);
        return data;
    }

    static getNewMessage = async () => {
        const {data} = await $host.post(`/message/newMessage`);
        return data;
    }

    static getMessages = async () => {
        const {data} = await $host.post(`/message/all?limit=1000000000`);
        return data;
    }

    static readMessages = async (id) => {
        const {data} = await $host.post(`/message/read/${id}`);
        return data;
    }
}