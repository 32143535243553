import React, { createContext, useContext, useEffect, useState } from "react";
import { io } from "socket.io-client";

const SOCKET_URL = process.env.REACT_APP_STATIC_URL; // Адрес WebSocket-сервера

const SocketContext = createContext(null);

export const SocketProvider = ({ children }) => {
    const [socket, setSocket] = useState(null);

    useEffect(() => {
        const socketInstance = io(SOCKET_URL, { transports: ["websocket"] });

        socketInstance.on("connect", () => {
            console.log("✅ Подключено к WebSocket-серверу");
        });

        socketInstance.on("disconnect", () => {
            console.log("❌ Отключено от WebSocket-сервера");
        });

        setSocket(socketInstance);

        return () => {
            socketInstance.disconnect();
        };
    }, []);

    return (
        <SocketContext.Provider value={socket}>
            {children}
        </SocketContext.Provider>
    );
};

// Хук для использования WebSocket в любом компоненте
export const useSocket = () => {
    return useContext(SocketContext);
};