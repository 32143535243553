import React from 'react';
import {Card, Flex, Input, notification, Space, Spin, Table, Tag} from "antd";
import {Link} from "react-router-dom";
import MessageMethods from "../methods/message.methods";
import dayjs from "dayjs";
import {UserMethods} from "../methods/user.methods";
import T from "./common/t";
import {observer} from "mobx-react-lite";

export const dateFormat = 'YYYY.MM.DD HH:mm'

const columns = [
    {
        title: <T>От кого</T>,
        dataIndex: 'fromId',
        key: 'fromId',
        width: '15%',
    },
    {
        title: <T>Кому</T>,
        dataIndex: 'toId',
        key: 'toId',
        width: '15%',
    },
    {
        title: <T>Тема</T>,
        dataIndex: 'theme',
        width: '20%',
        key: 'theme',
    },
    {
        title: <T>Статус</T>,
        dataIndex: 'status',
        width: '10%',
        key: 'status',
        render: (_, record) => (
            <Tag color={record.isRead ? 'green' : 'volcano'} key={record.isRead}>
                {record.isRead ? <T>ПРОЧИТАНО</T> : <T>НЕ ПРОЧИТАНО</T>}
            </Tag>
        )
    },
    {
        title: <T>Создано</T>,
        dataIndex: 'createdAt',
        width: '10%',
        key: 'createdAt',
        render: (_, record) => (
            dayjs(record.createdAt).format(dateFormat)
        )
    },
    {
        title: <T>Прочитано</T>,
        dataIndex: 'updatedAt',
        width: '10%',
        key: 'updatedAt',
        render: (_, record) => (
            record.updatedAt && dayjs(record.updatedAt).format(dateFormat)
        )
    },
    {
        title: '',
        key: 'action',
        width: '10%',
        render: (_, record) => (
            <Space size="middle">
                <Link to={`${record.id}`}><T>Перейти</T></Link>
            </Space>
        ),
    },
];

const MessagesComponent = () => {
    const [loading, setLoading] = React.useState(true);

    const [data, setData] = React.useState([]);
    const [mewM, setNeM] = React.useState(null);

    React.useEffect(() => {
        (async () => {
            const [
                profile,
                messages
            ] = await Promise.allSettled([
                    UserMethods.getProfile(),
                    MessageMethods.getMessages()
                ]
            )
            if (profile.status === "fulfilled" && messages.status === "fulfilled") {
                setData(Object.values(messages.value.data).reverse());
                setLoading(false);
            }
        })();
    }, [mewM])

    const [searchInput, setSearchInput] = React.useState('');
    const [filteredData, setFilteredData] = React.useState([]);
    const [emptyFilter, setEmptyFilter] = React.useState(false);
    const [searched, setSearched] = React.useState(false);

    const [api, contextHolder] = notification.useNotification();
    const openNotificationWithIcon = (type, message, errors) => {
        api[type]({
            message,
            description: errors[0],
        });
    };



    // long pooling start

    // const subscribe = async () => {
    //     try {
    //         const message = await MessageMethods.getNewMessage();
    //         setNeM(message);
    //         await subscribe();
    //     } catch (e) {
    //         setTimeout(async () => {
    //             await subscribe()
    //         }, 5000)
    //     }
    // }
    //
    // React.useEffect(() => {
    //     subscribe().then()
    // }, [])


    // long pooling end

    const search = (e) => {
        setSearchInput(e.target.value);
    }

    React.useEffect(() => {
        const filtered = data.filter(d =>
            d.toId.toLowerCase().includes(searchInput.toLowerCase()) ||
            d.theme.toLowerCase().includes(searchInput.toLowerCase())
        )
        setTimeout(() => {
            setFilteredData(() => filtered)
            setSearched(() => true)
            if (filtered.length === 0) {
                setEmptyFilter(() => true)
            } else {
                setEmptyFilter(() => false)
            }
        }, 300);
    }, [searchInput]);

    const err = React.useCallback(() => {
        openNotificationWithIcon('error', <T>По вашему запросу ничего не найдено</T>, []);
    }, []);

    React.useMemo(() =>  {
        if (emptyFilter) {
            err();
        }
    }, [emptyFilter, err]);

    return (
        loading
            ?
            <Flex justify="center" align="center" style={{height: 'calc(100vh - 148px)'}}>
                <Spin size="large" />
            </Flex>
            :
            <>
                {searched && contextHolder}
                <Flex justify="space-between">
                    <Flex vertical gap={20} style={{width: '100%'}}>
                        <Table
                            style={{maxWidth: '100%', borderRight: '1px solid #f0f0f0'}}
                            pagination={false}
                            scroll={{x:'max-content', y: 'calc(100vh - 13rem)'}}
                            columns={columns}
                            dataSource={filteredData.length > 0 ? filteredData : data
                        } />
                    </Flex>

                    <Flex vertical style={{width: '20%'}}>
                        <Flex vertical style={{width: '100%', position: 'sticky', top: '0'}}>
                            <Card style={{boxShadow: 'none'}} bordered={false}>
                                <Input placeholder="Search..." value={searchInput} onChange={search} />
                            </Card>
                        </Flex>
                    </Flex>
                </Flex>
            </>



    );
};

export default observer(MessagesComponent);