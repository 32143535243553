import React from 'react';
import MainComponent from "../components/common/main.component";
import MessagesComponent from "../components/messages.component";
import {Flex, Form, Input, Modal, notification, Select, Spin} from "antd";
import {UserMethods} from "../methods/user.methods";
import {jwtDecode} from "jwt-decode";
import MessageMethods from "../methods/message.methods";
import {useAuth} from "../hooks/useAuth";
import {observer} from "mobx-react-lite";
import T from "../components/common/t";
import AllComponent from "../components/common/all.component";
import ReactQuill from "react-quill";
import {toolbarOptions} from "../components/web/oneWeb.component";
import {useSocket} from "../providers/socket.provider";

const MessagesPage = () => {
    const [loadingForm, setLoadingForm] = React.useState(false);
    const [isModalOpen, setIsModalOpen] = React.useState(false);
    const [options, setOptions] = React.useState([])
    const [uId, setUid] = React.useState("")
    const user = useAuth();
    const socket = useSocket();

    const [toIds, setToUIds] = React.useState([])

    const [form] = Form.useForm();

    React.useEffect(() => {
        if (user) {
            (async () => {
                const [users] = await Promise.allSettled([
                    UserMethods.getUsers(),
                ])
                if (users.status === "fulfilled") {
                    const {id} = jwtDecode(localStorage.getItem("token"));
                    setUid(id);
                    for (const user of Object.values(users.value.data)) {
                        if (id !== user.id){
                            setOptions(prev => [...prev, {
                                label: user.name,
                                value: user.id
                            }])
                        }
                    }
                }
            })()
        }
    }, [user])

    const validateMessages = {
        // eslint-disable-next-line
        required: '${label} ' + 'is required',
        types: {
            // eslint-disable-next-line
            email: '${label} ' + 'is not a valid email',
            // eslint-disable-next-line
            tel: '${label} ' + 'is not a valid phone number',
        },
    };

    const [api, contextHolder] = notification.useNotification();

    const openNotificationWithIcon = (type, message, errors) => {
        api[type]({
            message,
            description: errors && errors[0],
        });
    };

    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleOk = () => {
        if (!socket) return;
        setLoadingForm(true);
        form.setFieldsValue("");
        MessageMethods.addMessage({fromId: uId, toId: toIds, ...form.getFieldsValue(), isRead: false})
            .then(res => {
                form.resetFields();
                openNotificationWithIcon('success', <T>Сообщение/я успешно отправлено/ы</T>, []);
                socket.emit('message', {fromId: uId, toId: toIds, ...form.getFieldsValue(), isRead: false})
                // setTimeout(() => {
                //     setIsModalOpen(false);
                //     setLoadingForm(false);
                //     window.location.reload();
                // }, 1000);
            })
            .catch(error => {
                console.log(error);
                openNotificationWithIcon('error', error.response?.data?.message, error.response?.data?.errors)
            })
            .finally(() => setLoadingForm(false));
    };

    if (!socket) return
    socket.on("message", (msg) => {
        console.log(msg)
    })

    const handleCancel = () => {
        setIsModalOpen(false);
        form.resetFields();
    };

    const toggleUsers = (value) => {
        setToUIds(() => value)
    }

    return (
        <AllComponent>
            <MainComponent title={<T>Messages</T>} showModal={showModal} showButton={true}>
                <MessagesComponent />
                <Modal
                    title={<T>Add new message</T>} width={800} open={isModalOpen} onOk={handleOk} onCancel={handleCancel}
                >
                    {contextHolder}
                    {
                        loadingForm
                            ?
                            <Flex justify="center" align="center" style={{height: '176px'}}>
                                <Spin size="large" />
                            </Flex>
                            :
                            <Form
                                style={{ width: '100%'}}
                                form={form}
                                multipart={"form-data"}
                                layout="vertical"
                                validateMessages={validateMessages}
                            >
                                <Form.Item
                                    hasFeedback
                                    label={<T>Кому</T>}
                                    name="userId"
                                    rules={[
                                        {
                                            required: true,
                                        },
                                    ]}
                                >
                                    <Select
                                        mode="multiple"
                                        size={'large'}
                                        style={{ width: '100%' }}
                                        options={options}
                                        onChange={toggleUsers}

                                        showSearch filterOption={(input, option) => (String(option?.label) ?? '').includes(String(input))}
                                        filterSort={(optionA, optionB) =>
                                            (String(optionA?.label) ?? '').toLowerCase().localeCompare((String(optionB?.label) ?? '').toLowerCase())
                                        }
                                    />
                                </Form.Item>

                                <Form.Item
                                    hasFeedback
                                    label={<T>Тема</T>}
                                    name="theme"
                                    rules={[
                                        {
                                            required: true,
                                        },
                                    ]}
                                >
                                    <Input size={"large"} />
                                </Form.Item>

                                <Form.Item
                                    hasFeedback
                                    label={<T>Текст</T>}
                                    name="message"
                                    rules={[
                                        {
                                            required: true,
                                        },
                                    ]}
                                >

                                    <ReactQuill

                                        style={{width: '100%', maxWidth: '100%', marginBottom: 0, marginTop: '1rem'}}
                                        theme="snow"
                                        modules={
                                            {
                                                toolbar: toolbarOptions
                                            }
                                        }
                                    />

                                </Form.Item>
                            </Form>
                    }
                </Modal>
            </MainComponent>
        </AllComponent>
    );
};

export default observer(MessagesPage);